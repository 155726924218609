/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { BehaviorSubject } from 'rxjs';
import { msg } from './http-messages';
import { history } from '.';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');

const currentUserSubject = new BehaviorSubject(currentUser);
class API {
  aService: AxiosInstance;

  constructor() {
    const service = axios.create();
    service.interceptors.request.use(
      (config: AxiosRequestConfig | any) => {
        const { token } = JSON.parse(
          sessionStorage.getItem('currentUser') || '{}',
        );
        config.headers['Content-Type'] = 'application/json; charset=utf-8';
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
        config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, X-Auth-Token, Accept, Authorization';
        config.headers['Accept'] = 'application/json';
        config.headers['Cache-Control'] = 'max-age=31536000';
        if (token) config.headers['Authorization'] = `JWT ${token}`;

        return config;
      },
      (error) => Promise.reject(error),
    );
    service.interceptors.response.use(
      this.handleSuccessResponse,
      this.handleErrorResponse,
    );

    this.aService = service;
  }

  // eslint-disable-next-line class-methods-use-this
  handleSuccessResponse(
    response: Record<string, any>,
  ): Promise<any> | Record<string, any> {
    switch (response.data.status) {
      case 201:
        toast.success(response.data.message);
        break;
      case 200:
        return response;
      default:
        toast.success(`${response.data.status}: ${response.data.message}`);
        break;
    }
    return Promise.resolve(response);
  }

  handleErrorResponse = (error: Record<string, any>): Promise<void> => {
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          toast.error(
            `${
              error.response.data.message || msg.ACTION_UNAUTHORIZED
            }`,
          );
          history.push('/');
          break;
        case 403:
          toast.error(
            `${
              error.response.data.message || msg.ACTION_UNAUTHORIZED
            }`,
          );
          history.push('/');
          break;
        case 404:
          toast.error(
            ` ${
              error.response.data.message || msg.ACTION_NOT_FOUND
            }`,
          );
          break;
        case 408:
          toast.error(
            `${msg.ACTION_REQUEST_TIMED_OUT}`,
          );
          break;
        case 409:
          toast.warn(
            `${error.response.data.message}`,
          );
          break;
        case 500:
          toast.error(
            `${
              error.response.data.message || msg.ACTION_INTERNAL_SERVER
            }`,
          );
          break;
        case 502:
          toast.warn(`${msg.ACTION_BAD_GATEWAY}`);
          break;
        case 503:
          toast.warn(
            `${msg.ACTION_SERVICE_UNAVAILABLE}`,
          );
          break;
        case 507:
          toast.warn(
            `${msg.ACTION_INSUFFICIENT_STORAGE}`,
          );
          break;
        case 400:
          toast.error(
            `${error.response.data.message}`,
          );
          break;
        default:
          toast.error(`${error.response.statusText}`);
          break;
      }
    }
    return Promise.reject(error);
  };

  get(
    path: string,
    callback: (args: Array<Record<string, any>> | []) => void,
  ): Promise<any> {
    return this.aService
      .request({
        method: 'GET',
        url: `${BASE_URL}${path}`,
        responseType: 'json',
      })
      .then((response: Record<string, any>) => callback(response.data));
  }

  patch(
    path: string,
    payload: Record<string, any>,
    callback: (args: Record<string, any>) => void,
  ): Promise<any> {
    return this.aService
      .request({
        method: 'PATCH',
        url: `${BASE_URL}${path}`,
        responseType: 'json',
        data: payload,
      })
      .then((response: Record<string, any>) => callback(response.data));
  }

  post(
    path: string,
    payload: Record<string, any>,
    callback: (args: Record<string, any>) => void,
  ): Promise<any> {
    return this.aService
      .request({
        method: 'POST',
        url: `${BASE_URL}${path}`,
        responseType: 'json',
        data: payload,
      })
      .then((response: Record<string, any>) => callback(response.data));
  }

  put(
    path: string,
    payload: Record<string, any>,
    callback: (args: Record<string, any>) => void,
  ): Promise<any> {
    return this.aService
      .request({
        method: 'PUT',
        url: `${BASE_URL}${path}`,
        responseType: 'json',
        data: payload,
      })
      .then((response: Record<string, any>) => callback(response.data));
  }

  // eslint-disable-next-line class-methods-use-this
  logout(): void {
    // remove user from local storage to log user out
    sessionStorage.clear();
    currentUserSubject.next(null);
  }
}

export { API };
