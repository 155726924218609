import React from 'react';
import { useQuery, useMutation } from 'react-query';
import Moment from 'moment';
import Tooltip from 'react-tooltip';
import { FaPlus, FaUserEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  Layout, Content, Table, Button,
} from '../../components';
import { tableReducer } from '../../components/Table/TableReducer';
import { useSchoolAdminMenu } from './local-helpers/MenuItems';
import School, { IStatus } from './local-helpers/requests';
import CustomContext from '../../contexts/CustomContext';
import UpdateUser from './UpdateUser';
import { useCurrentUser } from '../../contexts/UserContext';
import ConfirmModal from '../../components/ConfirmModal';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const Users: React.FC = () => {
  const { user } = useCurrentUser();
  const menu = useSchoolAdminMenu();
  const API = React.useMemo(() => new School(), []);
  const [searchString, setSearchString] = React.useState('');
  const [isEdit, setIsEdit] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState<Record<string, any>>({});
  const route = useHistory();

  React.useEffect(() => () => toast.dismiss(), []);

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const {
    data,
    isSuccess,
    isLoading,
    refetch,
  } = useQuery(['accountants',
    state.queryPageIndex,
    state.queryPageSize,
    searchString],
  () => API.fetchUsers(state.queryPageIndex,
    state.queryPageSize,
    searchString,
    user?.schools[0]?.id));

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'UserId',
      },
      {
        Header: 'First Name',
        accessor: (row: Record<string, any>) => [row?.user?.first_name],
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.user?.first_name,
      },
      {
        Header: 'Last Name',
        accessor: (row: Record<string, any>) => [row?.user?.last_name],
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.user?.last_name,
      },
      {
        Header: 'Email',
        accessor: (row: Record<string, any>) => [row?.user?.email],
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.user?.email,
      },
      {
        Header: 'Phone number',
        accessor: (row: Record<string, any>) => [row?.user?.phone_number],
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => `+${original?.user?.phone_number}`,
      },
      {
        Header: 'Created at',
        accessor: (row: Record<string, any>) => [row?.created_at],
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.created_at)
          .format('DD/MM/YYYY, h:mm:ss a')
          || 'created at',
      },
      {
        Header: 'Updated at',
        accessor: (row: Record<string, any>) => [row?.updated_at],
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.updated_at)
          .format('DD/MM/YYYY, h:mm:ss a')
          || 'updated at',
      },
      // {
      //   Header: 'Edit',
      //   accessor: 'edit',
      //   Cell: ({
      //     row:
      //     { original },
      //   }: Record<string, any>): React.ReactNode => (
      //     <Button
      //       type="button"
      //       variant="tertiary"
      //       size="small"
      //       onClick={() => { setIsEdit(true); setUserInfo(original); }}
      //     >
      //       <FaUserEdit size={18} />
      //     </Button>
      //   )
      //   ,
      // },
      {
        Header: 'Status',
        accessor: (row: any) => [row?.status],
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          const {
            mutate,
            isLoading: isChangingStatus,
          } = useMutation(
            (payload: IStatus) => API.changeStatus(payload, user.schools[0].id),
            {
              onSuccess() {
                refetch();
              },
            },
          );

          const [showConfirm, setShowConfirm] = React.useState(false);

          const handleStatusChange = (): void => {
            mutate({
              action: original?.status === 'active' ? 'deactivate' : 'activate',
              user_id: original?.user_id,
            });
          };

          const handleSubmit = (e: React.FormEvent): void => {
            e.preventDefault();
            setShowConfirm(true);
          };

          const buttonStyles = original?.status === 'active'
            ? 'text-green-alertText bg-green-alertText'
            : 'text-red-alertText bg-red-alertText';

          return (
            <>
              <form onSubmit={handleSubmit}>
                <button
                  type="submit"
                  data-tip
                  data-for="status-change"
                  className={`text-xs px-2 font-medium bg-opacity-10
                     rounded py-0.5 mr-1 ${buttonStyles}`}
                  disabled={isChangingStatus}
                >
                  {isChangingStatus ? 'Changing...' : original?.status}
                </button>
              </form>

              {showConfirm && (
                <ConfirmModal
                  message={`Are you sure you want to change the status to
                     ${original?.status === 'active' ? 'inactive' : 'active'
                  }?`}
                  onConfirm={() => {
                    handleStatusChange();
                    setShowConfirm(false);
                  }}
                  onCancel={() => setShowConfirm(false)}
                  isLoading={isChangingStatus}
                />
              )}

              <Tooltip id="status-change" type="light">
                Click to change status.
              </Tooltip>
            </>
          );
        },
      },

    ],
    [API, refetch, user.schools],
  );

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  return (
    <>
      <UpdateUser
        isUpdateUser={isEdit}
        onClose={() => { setIsEdit(false); setUserInfo({}); }}
        info={userInfo}
      />
      <Layout menuItems={menu}>
        <Content title="Users">
          <CustomContext.Provider value={stateProvider}>
            <div className="w-full flex justify-end">
              <Button
                type="button"
                className="flex justify-between align-middle"
                onClick={() => route.push('/school/create-account')}
              >

                <FaPlus className="text-lg mr-2" />
                {' '}
                Add user

              </Button>
            </div>
            <Table
              searchLabel="search users"
              data={isSuccess ? data?.data : []}
              columns={columns}
              meta={isSuccess ? data?.meta : []}
              countPage={
              isSuccess
                ? Math.ceil(state.totalCount / state.queryPageSize)
                : undefined
            }
              loading={isLoading}
              onChangeCallback={(e: any) => handleSearch(e)}
              search
            />
          </CustomContext.Provider>
        </Content>
      </Layout>
    </>
  );
};

export default Users;
