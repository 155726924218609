/* eslint-disable camelcase */
/* eslint-disable no-console */
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Button, InputText, Select } from '../../components';
import { ISelectedValue } from '../../components/Select';
import { Profile, IProfile } from './local-helpers/requests';
import { useCurrentUser } from '../../contexts/UserContext';
// import { API } from '../../helpers';

const profile = new Profile();

const SchoolAdminProfile: React.FC = () => {
  const { user } = useCurrentUser();
  const [edit, setEdit] = React.useState(false);
  const genders = [
    { value: 'MALE', label: 'MALE' },
    { value: 'FEMALE', label: 'FEMALE' },
  ];

  React.useEffect(() => () => toast.dismiss(), []);

  const {
    mutate,
    isLoading,
  } = useMutation((payload: IProfile) => profile.updateUserInformation(payload));

  const formik = useFormik({
    initialValues: {
      schap_fname: user?.first_name ?? '',
      schap_gender: user?.gender ?? '',
      schap_lname: user?.last_name ?? '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      schap_fname: Yup.string().min(3, 'Must be at least 3 characters.'),
      schap_gender: Yup.string(),
      schap_lname: Yup.string().min(3, 'Must be at least 3 characters.'),
    }),
    onSubmit: (values) => {
      if (!isEqual(formik.initialValues, values)) {
        mutate({
          first_name: values.schap_fname,
          last_name: values.schap_lname,
          gender: values.schap_gender,
        });
      } else toast.info('No changes were made');
    },
  });

  return (
    <>
      <span>
        <Button
          type="button"
          variant="tertiary"
          className="mr-2 mb-3"
          onClick={() => setEdit(!edit)}
        >
          update
        </Button>
        {edit && (
          <Button
            type="button"
            loading={isLoading}
            onClick={() => { formik.handleSubmit(); setEdit(false); }}
          >
            save changes
          </Button>
        )}
        <div className="grid grid-cols-2 gap-10">
          <div className="flex flex-col">
            <span className="text-sm text-gray-dark">First Name</span>
            <span className="capitalize">
              {!edit ? (
                user?.first_name
              ) : (
                <InputText
                  id="schap_fname"
                  type="text"
                  value={formik.values.schap_fname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-sm text-gray-dark">Last Name</span>
            <span className="capitalize">
              {!edit ? (
                user?.last_name
              ) : (
                <InputText
                  id="schap_lname"
                  type="text"
                  value={formik.values.schap_lname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-sm text-gray-dark">Gender</span>
            <span className="capitalize">
              {!edit ? (
                user?.gender
              ) : (
                <Select
                  id="schap_gender"
                  onChange={(option: ISelectedValue) => {
                    formik.setFieldValue('schap_gender', option.value);
                  }}
                  onBlur={formik.handleBlur}
                  options={genders}
                  defaultValue={{
                    label: formik.values.schap_gender,
                    value: formik.values.schap_gender,
                  }}
                />
              )}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-sm text-gray-dark">Email</span>
            <span>{user?.email}</span>
          </div>
          <div className="flex flex-col">
            <span className="text-sm text-gray-dark">Username</span>
            <span>{user?.username}</span>
          </div>
          <div className="flex flex-col">
            <span className="text-sm text-gray-dark">Phone Number</span>
            <span>
              +
              {user?.phone_number?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            </span>
          </div>
        </div>
      </span>
    </>
  );
};

export { SchoolAdminProfile };
