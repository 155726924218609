/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { API } from '../../../helpers';

const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');

export interface ISchoolProfile {
  logo?: string;
  name?: string;
  abbreviation?: string;
  email?: string;
  address?: string;
  website?: string;
}

export interface IProfile {
  phone_number?: string,
  first_name?: string,
  last_name?: string,
  gender?: string,

}
export interface IChangePass {
  password: string;
}

const api = new API();

class Profile {
  user: Record<string, any>;

  constructor() {
    this.user = currentUser;
  }

  updateUserInformation(body: IProfile): Promise<any> {
    return api.put(
      `/schools/${this.user?.schools[0]?.id}/members/${this.user?.id}`,
      body,
      (res) => toast.success(res.message),
    );
  }

  updateSchoolInfo(body: ISchoolProfile): Promise<any> {
    return api.put(`/schools/${this.user?.schools[0]?.id}`,
      body,
      (res) => toast.success(res.message));
  }

  resetPassword(): Promise<any> {
    return api.put(
      `/schools/${this.user?.schools[0]?.id}/members/${this.user?.id}/reset_password`,
      {},
      (res) => toast.success(res.message),
    );
  }

  fetchCurrentUser = (): Promise<any> => api.get('/user', (res) => res)
}

export { Profile };
