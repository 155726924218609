/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import axios from 'axios';
import { InputText, Card, Button } from '../components';
import Main from '../assets/images/main.svg';
import { ROUTE } from '../routes';
import { Role } from '../helpers';
import Urubuto from '../assets/images/Urubuto_Logo.svg';

interface ILogin {
  email: string;
  password: string;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;
const NODE_ENV = process.env.REACT_APP_NODE_ENV;

const loginUser = async (payload: ILogin): Promise<any> => {
  const response = await axios({
    method: 'POST',
    url: `${BASE_URL}/auth/login`,
    responseType: 'json',
    data: payload,
  });
  return response.data;
};

const Login: React.FC = () => {
  const {
    mutate, isLoading, data, isSuccess,
  } = useMutation(loginUser, {
    onError: () => {
      toast.error('Login failed. Please try again.');
    },
  });

  React.useEffect(() => () => toast.dismiss(), []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address.')
        .required('Email is required.'),
      password: Yup.string()
        // .min(6, 'Must be at least 6 characters.')
        .required('Password is required.'),
    }),
    onSubmit: (values) => {
      mutate(values);
    },
  });
  React.useEffect(() => {
    if (isSuccess) {
      sessionStorage.setItem('currentUser', JSON.stringify(data.data));
      switch (data.data.role) {
        case Role.ADMIN:
          window.location.href = NODE_ENV === 'staging' ? `/mis${ROUTE.ADMIN_TRANSACTIONS}` : `${ROUTE.ADMIN_TRANSACTIONS}`;
          break;
        case Role.SCHOOL_ADMIN:
          window.location.href = NODE_ENV === 'staging'
            ? `/mis${ROUTE.SCHOOL_ADMIN}`
            : `${ROUTE.SCHOOL_ADMIN}`;
          break;
        case Role.ACCOUNTANT:
          window.location.href = NODE_ENV === 'staging'
            ? `/mis${ROUTE.SCHOOL_ADMIN_STUDENTS}`
            : `${ROUTE.SCHOOL_ADMIN_STUDENTS}`;
          break;
        default:
          window.location.href = NODE_ENV === 'staging' ? `/mis${ROUTE.HOME}` : `${ROUTE.HOME}`;
          break;
      }
    }
  }, [isSuccess, data]);

  return (
    <div className="w-full flex flex-wrap">
      {/* Login section */}
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center bg-blue h-screen">
        <div className="absolute top-6 left-6">
          <img src={Urubuto} alt="" width="173.07" height="50" />
        </div>
        <div className="w-full flex justify-center mt-8">
          <Card width={370}>
            <div className="flex flex-col justify-center md:justify-start">
              <p className="text-center text-3xl text-blue">Login.</p>
              <form
                className="flex flex-col pt-3 md:pt-8"
                onSubmit={formik.handleSubmit}
              >
                <InputText
                  type="email"
                  label="your e-mail/phone number"
                  id="email"
                  placeholder="doe@bkth.rw"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  errorMsg={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : ''
                  }
                />
                <InputText
                  type="password"
                  label="password"
                  id="password"
                  placeholder="*****"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  errorMsg={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : ''
                  }
                />
                <p className="text-right font-light text-xs mt-3 mr-5 text-blue hover:underline">
                  Forgot password?
                </p>
                <Button
                  type="submit"
                  variant="primary"
                  className="mt-6"
                  loading={isLoading}
                >
                  Log in
                </Button>
              </form>
            </div>
          </Card>
        </div>
      </div>

      {/* Image section (hidden on smaller screens) */}
      <div className="hidden md:flex md:w-1/2 shadow-2xl">
        <div className="w-full h-screen bg-white flex flex-col justify-center items-center">
          <img src={Main} width="500" height="264.26" alt="main cover" />
          <div className="mt-8 w-6/12">
            <h1 className="text-green font-extrabold text-4xl">
              School management made easy
            </h1>
            <p className="text-blue text-lg font-light">
              URUBUTO is a comprehensive school management solution developed
              for schools, students and parents. The intent is to maximize the
              productivity of the school in providing the best education a
              student can have.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
