import React from 'react';
import { MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Tabs } from '../../components';
import { PasswordResetSchool } from './PasswordResetSchool';
import { SchoolAdminProfile } from './SchoolAdminProfile';
import { SchoolInfo } from './SchoolInfo';
import { useCurrentUser } from '../../contexts/UserContext';

const SchoolProfile: React.FC = () => {
  const { user } = useCurrentUser();

  const school = user?.schools[0];
  const inputFile = React.useRef<HTMLInputElement | null>(null);
  const [upload, setUpload] = React.useState(false);
  // eslint-disable-next-line max-len
  const [selectedImage, setSelectedImage] = React.useState<Blob | MediaSource | null>(null);

  const onUpload = (): any => {
    inputFile.current?.click();
  };

  React.useEffect(() => () => toast.dismiss(), []);

  return (
    <div className="flex flex-col items-start">
      <div className="avatar flex flex-col items-center mb-2">
        <div
          className="relative flex -space-x-1 overflow-hidden p-2
              "
          onMouseOver={() => setUpload(true)}
          onMouseLeave={() => setUpload(false)}
          onFocus={() => setUpload(true)}
        >
          {selectedImage === null ? (
            <div
              className="object-cover w-32 h-32 rounded-full
            border-2 border-white bg-yellow text-yellow-light shadow-md
            flex flex-col items-center justify-center text-5xl font-bold"
            >
              {school?.name?.split('')[0]}
            </div>
          ) : (
            <img
              className="inline-block h-32 w-32 rounded-full
                   border-2 border-white shadow-md object-contain"
              src={URL.createObjectURL(selectedImage)}
              alt=""
            />
          )}
          {upload && (
            <button
              type="button"
              onClick={onUpload}
              className=" absolute right-3 bottom-5 w-7 h-7
            rounded-full bg-green border-2
            border-white shadow flex flex-col items-center
            justify-center p-1 "
              aria-label="upload logo"
            >
              <MdEdit className="text-white" />
              <input
                ref={inputFile}
                type="file"
                id="file"
                className=" hidden"
                onChange={(event: any) => {
                  setSelectedImage(event.target.files[0]);
                }}
              />
            </button>
          )}
        </div>
        <span className="text-3xl font-medium mt-3 text-gray-dark uppercase">
          {school?.abbreviation}
        </span>
        <span className="text-sm mt-1 capitalize text-gray-dark">
          {school?.name}
        </span>
      </div>

      <Tabs>
        Admin
        <SchoolAdminProfile />
        School
        <SchoolInfo />
        Reset Password
        <PasswordResetSchool />
      </Tabs>
    </div>
  );
};

export { SchoolProfile };
