import React from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';
import { Button, InputText } from '../../components';
import { urlReg } from '../../helpers';
import { Profile } from './local-helpers/requests';
import { useCurrentUser } from '../../contexts/UserContext';

const request = new Profile();

const SchoolInfo: React.FC = () => {
  const { user } = useCurrentUser();
  const school = user?.schools[0];

  React.useEffect(() => () => toast.dismiss(), []);

  const {
    mutate,
    isLoading,
  } = useMutation((payload: Record<string, any>) => request.updateSchoolInfo(payload));

  const formik = useFormik({
    initialValues: {
      school_name: school?.name,
      school_name_acro: school?.abbreviation,
      school_address: school?.address,
      school_email: school?.email,
      school_website: school?.website,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      school_name: Yup.string().min(3, 'Must be at least 3 characters.'),
      schap_name_acro: Yup.string().min(2, 'Must be at least 3 characters.'),
      school_address: Yup.string().min(3, 'Must be at least 3 characters.'),
      school_email: Yup.string().email('Invalid email address'),
      school_website: Yup.string().matches(urlReg, 'Website is invalid.'),
    }),
    onSubmit: (values) => {
      if (!isEqual(formik.initialValues, values)) {
        mutate({
          name: values.school_name,
          address: values.school_address,
          email: values.school_email,
          website: values.school_website,
          acronym: values.school_name_acro,
        });
      } else toast.info('No changes were made');
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-2 gap-5">
        <InputText
          id="school_name"
          type="text"
          label="name"
          value={formik.values.school_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <InputText
          id="school_name_acro"
          type="text"
          label="acronym"
          value={formik.values.school_name_acro}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <InputText
          id="school_address"
          type="text"
          label="address"
          value={formik.values.school_address}
          onChange={() => formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <InputText
          id="school_email"
          type="email"
          placeholder="email"
          label="email"
          value={formik.values.school_email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <InputText
          id="school_website"
          type="url"
          placeholder="website"
          label="school website"
          value={formik.values.school_website}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      <Button type="submit" className="mt-5" loading={isLoading}>
        Save Changes
      </Button>
    </form>
  );
};

export { SchoolInfo };
