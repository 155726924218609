/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { FormLayout, Button, Select } from '../../../../components';
import {
  Upload,
  convertJsonToXl,
  studentListTemplate,
} from '../../../../helpers/functions-and-objects';
import School from '../../local-helpers/requests';
import CreateCampus from './CreateCampus';
import CreateDepartment from './CreateDepartment';
import { ISelectedValue } from '../../../../../shared';
import { useCurrentUser } from '../../../../contexts/UserContext';

interface IOptions {
  label: string;
  value: string;
}

const UploadStudents: React.FC = () => {
  const { user } = useCurrentUser();
  const schoolName = user?.schools?.[0]?.name;
  const schoolId = user?.schools[0]?.id;
  const isGasabo = schoolName?.includes('Gasabo');
  const API = new School();

  const inputFile = React.useRef<HTMLInputElement | null>(null);
  const [isCreateCampus, setCreateCampus] = React.useState(false);
  const [isCreateDept, setCreateDept] = React.useState(false);
  const [selectedCampus, setSelectedCampus] = React.useState('');
  const [selectedDept, setSelectedDept] = React.useState('');
  const [validationMsgCamp, setValidationMsgCamp] = React.useState('');
  const [validationMsgDept, setValidationMsgDept] = React.useState('');
  const [campuses, setCampuses] = React.useState<Array<ISelectedValue>>([]);
  const [departments, setDepartments] = React.useState<Array<ISelectedValue>>([]);

  const onUpload = (): any => {
    inputFile.current?.click();
  };

  const { isLoading: isCampusFetching } = useQuery('campuses', () => API.fetchCampuses(schoolId), {
    onSuccess(data) {
      const { data: campusData } = data;
      const campuseselection = campusData.map((cd: Record<string, any>) => ({
        label: cd?.name,
        value: cd?.id,
      }));
      setCampuses((prev) => ([...prev, ...campuseselection]));
    },
  });

  // eslint-disable-next-line max-len
  const { mutate, isLoading } = useMutation((payload: FormData) => API.uploadStudents(payload, user.schools[0].id));

  const { isLoading: isDepartmentFetching } = useQuery('departments', () => API.fetchDepartments(
    100,
    schoolId,
  ), {
    retry: false,
    enabled: selectedCampus.trim().length > 0,
    onSuccess(data) {
      const { data: departmentData } = data;
      const departmentSelection = departmentData.map((d: Record<string, any>) => ({
        label: d?.name,
        value: d?.id,
      }));
      setDepartments((prev) => ([...prev, ...departmentSelection]));
    },
  });

  function uploadExcelFile(
    studentsFile: File,
    campusId: string,
    deptId: string,
  ): void {
    const formData = new FormData();

    formData.append('department_id', deptId);
    formData.append('campus_id', campusId);
    formData.append('file', studentsFile, studentsFile.name);

    mutate(formData);
  }

  const handleDeptSelect = (option: IOptions): void => {
    setSelectedDept(option.value);
  };

  return (
    <>
      <CreateCampus
        isGasabo
        isCreateCampus={isCreateCampus}
        onClose={() => setCreateCampus(false)}
      />
      <CreateDepartment
        isGasabo={isGasabo}
        isCreateDept={isCreateDept}
        onClose={() => setCreateDept(false)}
        campuses={campuses}
      />

      <FormLayout
        title={isGasabo ? 'Select Location' : 'Select campus'}
        description={
          isGasabo
            ? `Select the location before selecting
          the zone and uploading the sellers' list.
          If you don't find the campus in the dropdown
          it's because you most likely haven't created one or selected a location.
          Click on the button to create the location in that case.`
            : `Select the campus before selecting
          the department and uploading the students' list.
          If you don't find the campus in the dropdown
          it's because you most likely haven't created one or selected a campus.
          Click on the button to create the campus in that case.`
        }
      >
        <div className="w-1/2">
          <Select
            id="sch_campuses"
            placeholder={
              isCampusFetching
                ? isGasabo
                  ? 'fetching locations...'
                  : 'fetching campuses...'
                : isGasabo
                  ? 'Locations...'
                  : 'Campuses...'
            }
            label={isGasabo ? 'select location' : 'select campus'}
            options={campuses}
            onChange={(option: IOptions) => {
              setSelectedCampus(option.value.toString());
            }}
            errorMsg={
              validationMsgCamp && selectedCampus === ''
                ? validationMsgCamp
                : ''
            }
          />
          <p className="text-gray-dark text-xs ml-5 mb-2 italic">
            {isGasabo
              ? 'Not seeing the location you are looking for? You can create one.'
              : 'Not seeing the campus you are looking for? You can create one.'}
          </p>
          <Button
            type="button"
            variant="tertiary"
            onClick={() => setCreateCampus(true)}
          >
            {isGasabo ? 'Create location' : 'Create campus'}
          </Button>
        </div>
      </FormLayout>
      <FormLayout
        title={isGasabo ? 'Select zone' : 'Select department'}
        description={
          isGasabo
            ? `Select the zone after selecting the
          location and before uploading the sellers' list.
           If you don't find the zone in the dropdown
           it's because you most likely haven't created one.
            Click on the button to create the zone in that case.`
            : `Select the department after selecting the
          campus and before uploading the students' list.
           If you don't find the department in the dropdown
           it's because you most likely haven't created one.
            Click on the button to create the department in that case.`
        }
      >
        <div className="w-1/2">
          <Select
            id="sch_dept"
            placeholder={
              isDepartmentFetching
                ? isGasabo
                  ? 'fetching zones'
                  : 'fetching departments...'
                : isGasabo
                  ? 'Zones...'
                  : ' Departments...'
            }
            label="select department"
            options={departments}
            onChange={(option: IOptions) => handleDeptSelect(option)}
            errorMsg={
              validationMsgDept && selectedDept === '' ? validationMsgDept : ''
            }
          />
          <p className="text-gray-dark text-xs ml-5 mb-2 italic">
            {isGasabo
              ? ' Not seeing the zone you are looking for? You can create one.'
              : ' Not seeing the department you are looking for? You can create one.'}
          </p>
          <Button
            type="button"
            variant="tertiary"
            onClick={() => setCreateDept(true)}
          >
            Create department
          </Button>
        </div>
      </FormLayout>
      <FormLayout
        title="Upload list"
        description="Before uploading the list it's recommended to
          check out the valid template by clicking on the download
           template button. "
      >
        <div className="flex items-center">
          <Button
            type="button"
            variant="tertiary"
            className="mr-4"
            onClick={() => convertJsonToXl([[]], studentListTemplate)}
          >
            Download the template
          </Button>
          <div className="flex flex-col items-center mt-4">
            <form method="post" encType="multipart/form-data">
              <Button
                type="button"
                loading={isLoading}
                onClick={() => (selectedCampus !== '' && selectedDept !== ''
                  ? onUpload()
                  : (setValidationMsgCamp('Please select a campus'),
                  setValidationMsgDept('Please select a department')))}
                disabled={
                  selectedCampus.length === 0 || selectedDept.length === 0
                }
              >
                Upload list
                <input
                  ref={inputFile}
                  type="file"
                  id="file"
                  className="hidden"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => Upload(
                    e,
                    uploadExcelFile,
                    selectedCampus, selectedDept,
                  )}
                />
              </Button>
              <p className=" text-gray-dark italic text-xs mt-1 ml-6">
                Upload only an excel file.
              </p>
            </form>
          </div>
        </div>
      </FormLayout>
    </>
  );
};

export default UploadStudents;
